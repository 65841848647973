import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import axios from "axios"

export const getPriceStr = price => {
  let priceStr = ""
  if (price.min && price.max) {
    priceStr =
      " between " +
      `€` +
      addCommas(price.min) +
      " and " +
      `€` +
      addCommas(price.max)
  } else if (price.max) {
    priceStr = " under " + `€` + addCommas(price.max)
  } else if (price.min) {
    priceStr = " above " + `€` + addCommas(price.min)
  }
  return priceStr
}

export const getSizeStr = size => {
  let sizeStr = ""
  let squareValue = "m" + "<sup>2</sup>"

  if (size.min && size.max) {
    sizeStr =
      " with size from " +
      addCommas(size.min) +
      `${squareValue}` +
      " to " +
      addCommas(size.max) +
      `${squareValue}`
  } else if (size.max) {
    sizeStr = " with size below " + addCommas(size.max) + ` ${squareValue}`
  } else if (size.min) {
    sizeStr = " with size over " + addCommas(size.min) + ` ${squareValue}`
  }

  return sizeStr
}

function addCommas(num) {
  // console.log('num : ' + num);
  var str = num.toString().split(".")
  // console.log('str, ' + str);
  if (str[0].length >= 4) {
    // add comma every 3 digits before decimal
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join(".")
}

export const ACTION_TYPES = {
  residential_sales: " for sale in ",
  residential_lettings: " to rent in ",
  commercial_sales: " for sale in ",
  commercial_lettings: " to rent in ",
  auction_residential_: " for sale in ",
  auction_residential_sales: " for sale in ",
}

export const ACTION_DESC_TYPES = {
  residential_sales: " for sale ",
  residential_lettings: " to rent ",
  commercial_sales: " for sale ",
  commercial_lettings: " to rent ",
  auction_residential_: " for sale ",
  auction_residential_sales: " for sale ",
}

export const pTypeBase = {
  residential_sales: "/property/for-sale/",
  residential_lettings: "/property/to-rent/",
  commercial_sales: "/commercial-property/for-sale/",
  commercial_lettings: "/commercial-property/to-rent/",
  auction_residential_: "/auction-property/for-sale/",
  auction_residential_sales: "/auction-property/for-sale/",
}

export const PROPERTY_TYPES_H1 = {
  apartment: "apartments",
  house: "houses",
  bungalow: "bungalows",
  development_plot: "development plots",
  site: "sites",
  investment: "investments",
  farm: "farms",
  studio: "studio",
  office: "Office",
  duplex: "duplexes",
  detached_house: "detached houses",
  industrial: "industrials",
  industrial_units: "industrial units",
  business: "businesses",
  townhouse: "townhouses",
  investment: "investment",
  pubs_restaurants: "pubs-restaurants",
  hotels: "hotels",
  retail: "retail",
  retail_units: "retail units",
  farm_land: "farm land",
  development_land: "development land",
  parking_space: "parking space",
  farm_land: "farm-land",
  farm_land: "farm-land",
  live_work: "live work",
  terraced_house: "terraced house",
  semi_detached:"semi detached",
  detached:"detached",
  bungalow:"bungalow",
  country_house:"country house",
  penthouse:"penthouse",
  investment_property:"investment property"

}

export const AreaCounty = {
  carlow: "Co. Carlow",
  cavan: "Co. Cavan",
  clare: "Co. Clare",
  cork: "Co. Cork",
  donegal: "Co. Donegal",
  galway: "Co. Galway",
  kerry: "Co. Kerry",
  kildare: "Co. Kildare",
  kilkenny: "Co. Kilkenny",
  leitrim: "Co. Leitrim",
  limerick: "Co. Limerick",
  longford: "Co. Longford",
  louth: "Co. Louth",
  mayo: "Co. Mayo",
  meath: "Co. Meath",
  monaghan: "Co. Monaghan",
  offaly: "Co. Offaly",
  roscommon: "Co. Roscommon",
  sligo: "Co. Sligo",
  tipperary: "Co. Tipperary",
  waterford: "Co. Waterford",
  westmeath: "Co. Westmeath",
  wexford: "Co. Wexford",
  wicklow: "Co. Wicklow",
  dublin: "Dublin",
}

export const ReplaceAreas =
  "carlow|cavan|clare|cork|donegal|galway|kerry|kildare|kilkenny|leitrim|limerick|longford|louth|mayo|meath|monaghan|offaly|roscommon|sligo|tipperary|waterford|westmeath|wexford|wicklow|dublin"

export const toTitleCase = text => {
  let _text = text || ""
  // console.log('_text : ' + _text);
  if (_text) {
    _text = _text.trim().split(" ")
    // console.log('_text.length : ' + _text.length);
    if (_text.length > 0) {
      _text = _text
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(" ")
    }
  }
  return _text
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, pcategorytype) => {
  //console.log("$$$$$$", pcategorytype, pType);
  var replace_url = pcategorytype + "_" + pType

  if (location) {
    var query = ""
    let pathUri_main = location?.split(pTypeBase[replace_url]?.slice(0, -1))
    var areaVal = ""
    var bedVal = ""
    var minpriceVal = ""
    var maxpriceVal = ""
    var typeVal = ""
    var minsizeVal = ""
    var maxsizeVal = ""
    var mustIncludeVal = ""
    var officeId = ""

    //console.log("$$$$$---url---####--", location+"----"+pathUri_main+"----"+pTypeBase+"-----"+pType+"----"+pcategorytype);

    // console.log("price__ 0",location, pType, pTypeBase[pType], pcategorytype, pathUri_main);

    //console.log("pathUri_main", pathUri_main)

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //console.log("price__ 1", pathUri.length);

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }
        // for-sale
        if (pathUri[vi].indexOf("for-sale") >= 0) {
          // its area
          pType = "sales"
        }
        if (pathUri[vi].indexOf("to-rent") >= 0) {
          // its area
          pType = "lettings"
        }
        if (pathUri[vi].indexOf("in-") >= 0) {
          // its area
          var areaArrfilt = pathUri[vi].replace("in-", "")
          areaVal = areaArrfilt.split("-and-")
          // areaVal1.map((item) => {
          //   areaVal=item.replace("-", " ")
          //   console.log("#######", areaVal)
          // })

          // areaVal = pathUri[vi].replace("in-","").replace("-"," ");
        }
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          // its bedrooms
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }
        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        //console.log("price__", pathUri[vi].indexOf("for-sale"));

        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          // its price
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Size
        if (pathUri[vi].indexOf("size-") >= 0) {
          let sizeFilt1 = pathUri[vi].split("over-")
          if (sizeFilt1[1]) {
            minsizeVal = Math.round(sizeFilt1[1] * 0.092903)
          }
          let sizeFilt2 = pathUri[vi].split("below-")
          if (sizeFilt2[1]) {
            maxsizeVal = Math.round(sizeFilt2[1] * 0.092903)
          }
          let sizeFilt3 = pathUri[vi].split("-from-")
          if (sizeFilt3[1]) {
            let sizeFilt4 = sizeFilt3[1].split("-to-")
            minsizeVal = Math.round(sizeFilt4[0] * 0.092903)
            maxsizeVal = Math.round(sizeFilt4[1] * 0.092903)
          }
        }
        // Size

        // Must Include
        if (pathUri[vi].indexOf("with-") >= 0) {
          var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
          mustIncludeVal = mustIncludeArrfilt.split("-and-")
        }

        // Office
        if (pathUri[vi].indexOf("dng-office-") >= 0) { 
          officeId = pathUri[vi].replace("dng-office-", "")
        }
        let priceFilt5 = pathUri[vi].split("type-")
        if (priceFilt5[1]) {
          typeVal = priceFilt5[1]
        }
      }
    }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === "undefined") {
      areaVal = "Ireland" // TODO
    }

    // var areaAlias = areaVal?.replace(' ', '-');
    // var areaAlias = areaVal

    // console.log("$$$$$$$---parse---", areaVal)

    return {
      pType: pType,
      areas: areaVal,
      // areas_alias: areaAlias,
      officeId: officeId,
      bedrooms: bedVal,
      pcategorytype: pcategorytype,
      type: typeVal,
      mustInclude: mustIncludeVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      size: {
        min: minsizeVal,
        max: maxsizeVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true,
      },
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = params => {
  const { areas, bedrooms, pType, type, price, size, mustInclude } = params

  return {
    department: "residential",
    searchType: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice: price.min,
    maxPrice: price.max,
    minSize: size.min,
    maxSize: size.max,
    building: type,
    must_Include: mustInclude,
  }
}

// TODO: hard coded details to be removed/replaced with common variables
const capitalize = s => {
  if (typeof s !== "string") return ""
  return s?.charAt(0).toUpperCase() + s?.slice(1)
}

// export const replaceMultiple = (text, characters) => {
//   for (const [i, each] of characters?.entries()) {
//     const previousChar = Object.keys(each);
//     const newChar = Object.values(each);

//     text = text?.replaceAll(previousChar, newChar);
//   }

//   return text
// }

export const propertyH1 = (params, pcategorytype, h1_areas) => {
  // console.log('params : ' + params);

  const { areas, bedrooms, pType, type, price, size, extra, mustInclude, officeId } =
    params
  //console.log(bedrooms, "rooms") 
  var replace_url = pcategorytype + "_" + pType

  let h1 = ""
  let building = ""

  //console.log("areas h1", areas)


  const capitalize=(str)=>{
    let text=str.replace(/-/g, " ")

    text = text.split(" ");
    for (var i = 0, x = text.length; i < x; i++) {
    if(text[i]!==""){
      text[i] = text[i][0].toUpperCase() + text[i].substr(1);
    }
  }

    return text.join(" ");
}
  
 

  if ("commercial" === pcategorytype) {
    // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial "
    if (mustInclude) {
      h1 += _.upperFirst(mustInclude.join(" and ")) + " "
    }

    if (type) {
      //building = ` ${PROPERTY_TYPES_H1[type.replace(/-/g, '_')]} Properties`
      building = ` ${PROPERTY_TYPES_H1[type.replace(/-/g, "_").replace("&_", "")]} `
    } else {
      building = `Commercial Property `
    }

    h1 += toTitleCase(building)

    if(officeId != ""){
      h1 += ACTION_TYPES[replace_url].replace("in", "through")
    }else{
      h1 += ACTION_TYPES[replace_url]
    }

    if (h1_areas?.length > 0) {
      let _areas = h1_areas.join(" and ")
      h1 += _areas.replace("north", "North").replace("road", "Road")
      h1 = h1.replace(/,/g, ", ")
    } else if (areas) {
      let _areas = areas
      // _areas = _areas.replace("-"," ");
      if (typeof _areas == "object") {
        _areas = _areas?.map(areaItem =>
          _.upperFirst(areaItem.replace("-", " "))
        )
        _areas = decodeURIComponent(_areas)
        // _areas = _.startCase(_.toLower(_areas.replace(/[^a-zA-Z ]/g, " ").replace(/\s+/g, ' ')).replace("dng", "DNG"));
        _areas = capitalize(_.toLower(_areas.replace(/\s+/g, ' ')).replace("dng", "DNG"));
      }
      // _areas = _areas.map((areaItem) => _.upperFirst(areaItem.replace("-"," ")));
      // _areas = decodeURIComponent(_areas);
      // _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north", "North").replace("road", "Road")
      if(officeId != ""){
        h1 += " estate agents";
      }
    }

    if (price && (price.min || price.max)) {
      h1 += getPriceStr(price)
    }

    if (size && (size.min || size.max)) {
      h1 += getSizeStr(size)
    }

    // if (mustInclude) {
    //   h1 += " with " + mustInclude.join(" and ")
    // }
  } else if (
    "auction_commercial" === pcategorytype ||
    "auction_residential" === pcategorytype
  ) {
    building = `Auction Property `

    //console.log("replace_url ==>", replace_url)

    h1 += toTitleCase(building)
    h1 += ACTION_TYPES[replace_url]

    if (h1_areas?.length > 0) {
      let _areas = h1_areas.join(" and ")
      h1 += _areas.replace("north", "North").replace("road", "Road")
      h1 = h1.replace(/,/g, ", ")
    } else if (areas) {
      let _areas = areas
      // _areas = _areas.replace("-"," ");
      if (typeof _areas == "object") {
        _areas = _areas?.map(areaItem =>
          _.upperFirst(areaItem.replace("-", " "))
        )
        _areas = decodeURIComponent(_areas)
        // _areas = _.startCase(_.toLower(_areas.replace(/[^a-zA-Z ]/g, " ").replace(/\s+/g, ' ')).replace("dng", "DNG"));
        _areas = capitalize(_.toLower(_areas.replace(/\s+/g, ' ')).replace("dng", "DNG"));
      }
      // _areas = _areas.map((areaItem) => _.upperFirst(areaItem.replace("-"," ")));
      // _areas = decodeURIComponent(_areas);
      // _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += _areas.replace("north", "North").replace("road", "Road")
    }

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? "+" : "+"
      h1 += " with more than " + bedrooms + " bedrooms "
    }

    if (price && (price.min || price.max)) {
      h1 += getPriceStr(price)
    }

    if (mustInclude) {
      h1 += " with " + mustInclude.join(" and ")
    }
  } else {
    
    let building = "Property"
    // if (bedrooms)
    //   building = "properties";

    if (mustInclude) {
      h1 += _.upperFirst(mustInclude.join(" and ")) + " "
    }

    if (type) {

      building = ` ${PROPERTY_TYPES_H1[type.replace(/-/g, "_")]} `
    } else {
      building = ` Property `
    }

    h1 += mustInclude?building:toTitleCase(building)
    if(officeId != ""){
      h1 += ACTION_TYPES[replace_url].replace("in", "through")
    }else{
      h1 += ACTION_TYPES[replace_url]
    }
   

    if (h1_areas?.length > 0) {
      let _areas = h1_areas.join(" and ")
      h1 += _areas.replace("north", "North").replace("road", "Road")
      h1 = h1.replace(/,/g, ", ")
    } else if (areas) {
      let _areas = areas
      //console.log("$$$$$--utils--- 1", _areas)
      // _areas = _areas.replace("-"," ");
      if (typeof _areas == "object") {
        _areas = _areas?.map(areaItem =>
          _.upperFirst(areaItem.replace("-", " "))
        )
        _areas = decodeURIComponent(_areas)
        // _areas = _.startCase(_.toLower(_areas.replace(/[^a-zA-Z ]/g, " ").replace(/\s+/g, ' ')).replace("dng", "DNG"));
        _areas = capitalize(_.toLower(_areas.replace(/\s+/g, ' ')).replace("dng", "DNG"));
      }
      // _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;

      //console.log("$$$$$--utils--- 2", _areas)
      h1 += _areas.replace("north", "North").replace("road", "Road")
      h1 = h1.replace(/,/g, ", ")
      if(officeId != ""){
        h1 += " estate agents";
      }

    }

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? "+" : "+"
      h1 += " with more than " + bedrooms + " bedrooms "
    }

    if (price && (price.min || price.max)) {
      h1 += getPriceStr(price)
    }

    
  }

  // console.log("H1 title => ", h1);
  return h1
}

export const getAreas = async () => {
  let url = `${process.env.GATSBY_STRAPI_SRC}/${"areas.json"}`
  const { data } = await axios.get(url, {})

  return data
}

export const commercialFilters = [
  {name: "On-Site Security", slug: "on-site-security"},
  {name: "24 Hours Access", slug: "24-hours-access"},
  {name: "HGV Access", slug: "hgv-access"},
  {name: "Rear Access", slug: "rear-access"},
  {name: "Yard", slug: "yard"},
  {name: "Circulation Areas", slug: "circulation-areas"},
  {name: "Loading Bay", slug: "loading-bay"},
  {name: "Roller Shutter Access", slug: "roller-shutter-access"},
  {name: "Roller Shutter Door", slug: "roller-shutter-door"},
  {name: "Display Frontage", slug: "display-frontage"},
]

export const lettingsFilters = [
  {name: "Central Heating", slug: "central-heating"},
  {name: "Pets", slug: "pets"},
  {name: "Smoking", slug: "smoking"},
  {name: "Children", slug: "children"},
  {name: "Bond Scheme", slug: "bond-scheme"},
  {name: "Sharers", slug: "sharers"},
  {name: "Internet", slug: "internet"},
  {name: "Alarm", slug: "alarm"},
  {name: "Dishwasher", slug: "dishwasher"},
  {name: "Microwave", slug: "microwave"},
  {name: "Serviced", slug: "serviced"},
  {name: "Cable TV", slug: "cable-tv"},
  {name: "Wheelchair Accessible", slug: "wheelchair-accessible"},
  {name: "Dryer", slug: "dryer"},
]